import Vue from 'vue';

// import elements
import KButton from '@/components/elements/k-button.vue';
import KTooltip from '../adready-vue/components/elements/k-tooltip.vue';
import KTextInput from '../adready-vue/components/elements/k-text-input.vue';
import KCheckbox from '../adready-vue/components/elements/k-checkbox.vue';
import KSelect from '../adready-vue/components/elements/k-select.vue';

// Load custom components
// Register elements
Vue.component('Hoverable', () =>
  import(
    /* webpackChunkName: "k-components", webpackMode: "eager" */ '../adready-vue/components/elements/hoverable.vue'
  )
);
Vue.component('KTooltip', KTooltip);
Vue.component('KButton', KButton);
Vue.component('KTextInput', KTextInput);
Vue.component('KSelect', KSelect);
Vue.component('KCheckbox', KCheckbox);
// Site layout
