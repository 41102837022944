import { make } from 'vuex-pathify';
import { isDemoInstance } from '~/util/utility-functions';
import { RANGE_CUSTOM, COMPARE_RANGE_PREV_DAY, DEFAULT_CONVERSION_WINDOW } from '~/constant';

let startDate = new Date(new Date().setDate(new Date().getDate() - 30));
let endDate = new Date(new Date().setDate(new Date().getDate() - 1));
let compareStartDate = new Date(new Date().setDate(new Date().getDate() - 30));
let compareEndDate = new Date(new Date().setDate(new Date().getDate() - 2));

if (isDemoInstance()) {
  startDate = new Date(2022, 2, 9);
  endDate = new Date(2022, 3, 15);
  compareStartDate = new Date(2022, 2, 9);
  compareEndDate = new Date(2022, 3, 14);
}

const dates = {
  startDate,
  endDate,
  compareWith: true,
  compareStartDate,
  compareEndDate,
  dateRangeOption: RANGE_CUSTOM,
  dateCompareOption: COMPARE_RANGE_PREV_DAY,
};
const filters = {
  campaignOptions: [],
  adGroupOptions: [],
  mediaTypeOptions: [],
  audienceOptions: [],
  creativeOptions: [],
  publisherOptions: [],
  keywordOptions: [],
  pixelOptions: [],
  conversionWindow: DEFAULT_CONVERSION_WINDOW,
};

const state = {
  dates,
  filters,
  filtersApplied: false,
  filtersAppliedLoading: false,
  selectedDashboardTab: 'adgroup',
  preventDatesLoading: false,
  isConversionDisabled: false,
};

const getters = {
  GET_SELECTED_MEDIATYPES(st) {
    return st.filters.mediaTypeOptions.filter((f) => f.checked).map((m) => m.value);
  },
  GET_SELECTED_CREATIVES(st) {
    return st.filters.creativeOptions.filter((f) => f.checked).map((m) => m.key);
  },
  GET_SELECTED_PUBLISHERS(st) {
    return st.filters.publisherOptions.filter((f) => f.checked).map((m) => m.key);
  },
  GET_SELECTED_AUDIENCES(st) {
    return st.filters.audienceOptions.filter((f) => f.checked).map((m) => m.value);
  },
  GET_SELECTED_KEYWORDS(st) {
    return st.filters.keywordOptions.filter((f) => f.checked).map((m) => m.value);
  },
  GET_SELECTED_PIXEL_IDS(st) {
    const { pixelOptions } = st.filters;
    const selectedPixels = st.filters.pixelOptions.filter((f) => f.checked);
    if (pixelOptions.length === selectedPixels.length) {
      return [];
    }
    return selectedPixels.map((m) => m.key);
  },
  GET_SELECTED_CAMPAIGNS(st) {
    return st.filters.campaignOptions.filter((f) => f.checked).map((m) => m.value);
  },
  GET_SELECTED_CAMPAIGN_IDS(st) {
    const { campaignOptions } = st.filters;
    const selectedCampaignIds = st.filters.campaignOptions.filter((f) => f.checked);
    if (campaignOptions.length === selectedCampaignIds.length) {
      return [];
    }
    return st.filters.campaignOptions.filter((f) => f.checked).map((m) => m.key);
  },
  GET_SELECTED_AD_GROUP_STR(st) {
    const { adGroupOptions } = st.filters;
    if (adGroupOptions.length === 0) {
      return '';
    }
    const selectedAdGroupOptions = adGroupOptions.filter((f) => f.checked);
    if (!(selectedAdGroupOptions.length === adGroupOptions.length)) {
      const queryStrArray = [];
      selectedAdGroupOptions.forEach((adGroup) => {
        const { adreadyIds } = adGroup;
        adreadyIds.forEach((lid) => {
          if (lid.adreadyId && lid.startDate) {
            queryStrArray.push(`${lid.adreadyId}|${lid.startDate}`);
          }
        });
      });
      return queryStrArray.join(',');
    }
    return '';
  },
};

const actions = {
  resetAdvanceFilters: ({ commit }) => {
    commit('RESET_ADVANCE_FILTERS');
  },
  resetDates: ({ commit }) => {
    commit('SET_RESET_DATES');
  },
  deSelectAdvanceFilters: ({ commit }) => {
    commit('DESELECT_ADVANCE_FILTERS');
  },
};

const mutations = {
  ...make.mutations(state),
  SET_RESET_DATES(st) {
    if (!st) {
      return;
    }
    st.dates = dates;
  },
  SET_RESET_FILTERS(st) {
    if (!st) {
      return;
    }
    st.filters = filters;
  },
  SET_UPDATE_FILTERS_DATA(st, payload = {}) {
    if (!st) {
      return;
    }
    st.filters = { ...st.filters, ...payload };
  },
  SET_DATES(st, payload = {}) {
    if (!st) {
      return;
    }
    st.dates = payload;
  },
  RESET_ADVANCE_FILTERS(st) {
    if (!st || !st.filters) {
      return;
    }
    st.filters.mediaTypeOptions = [];
    st.filters.audienceOptions = [];
    st.filters.creativeOptions = [];
    st.filters.publisherOptions = [];
    st.filters.keywordOptions = [];
    st.filters.pixelOptions = [];
    st.filters.conversionWindow = DEFAULT_CONVERSION_WINDOW;
  },
  DESELECT_ADVANCE_FILTERS(st) {
    if (!st || !st.filters) {
      return;
    }
    st.filters.keywordOptions
      .filter((f) => f.checked)
      .forEach((m) => {
        m.checked = false;
      });
    st.filters.creativeOptions
      .filter((f) => f.checked)
      .forEach((m) => {
        m.checked = false;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
